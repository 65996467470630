<template>
  <div>

    <a-layout>
      <a-layout style="background: #fff;padding: 0;margin:0;padding: 10px;">
        <a-form-model layout="inline" :model="queryParam" >

          <a-row style="margin:16px;">
          
            <a-col :span="5">

              <a-form-model-item label="领卡会员账号" >
                <a-input v-model="queryParam.username" placeholder="领卡会员账号">

                </a-input>
              </a-form-model-item>

            </a-col>
                    <a-col :span="5">

                  <a-form-model-item label="持卡会员账号" >
                    <a-input v-model="queryParam.relation" placeholder="持卡会员账号">

                    </a-input>
                  </a-form-model-item>

                        </a-col>

                            <a-col :span="9">
                                <a-form-model-item label="起止时间"  >
                                    <a-range-picker  show-time @change="OnChange">
                                        <a-icon slot="suffixIcon" type="smile" />
                                    </a-range-picker>

                                </a-form-model-item>
                                <a-form-model-item>
                                    <a-button @click="handleSubmit" type="default" icon="search">
                                        搜索
                                    </a-button>
                                </a-form-model-item>

                            </a-col>


          </a-row>

        </a-form-model>
      </a-layout>
      <a-layout style="background: #fff;padding: 0;margin:16px 0;padding: 16px;">

        <a-table size="small" :rowKey="record => record.Id" :pagination="pagination"   @change="handleTableChange" :columns="columns" :data-source="data" bordered>
         
           

        </a-table>
      </a-layout>
    </a-layout>
  </div>

</template>

<script>
export default {
  name: "index",
  data(){
    return {
      modeltitle:'',
      visible:false,
      cardtypelist:[],
      pagination: {
        total: 0,
        pageSize: 18,//每页中显示10条数据
        showSizeChanger: true,
        pageSizeOptions: ["18", "20", "50", "100"],//每页中显示的数据
        showTotal: total => `共有 ${total} 条数据`,  //分页中显示总的数据
      },
      topsize:'large',
      queryParam:{
        page:1,
        size:18,
        username:'',
        relation:''


      },

      admin:{},
      roleOption:[

      ],
      columns:[

    

        {
          title: '编号',

          dataIndex: 'Number'
        },
        {
          title: '卡类型Id',
          dataIndex: 'TypeId'

        },
        {
          title: '领卡会员账号',
          dataIndex: 'Member',
        },
        {
          title: '持卡会员账号',
          dataIndex: 'Relation',
        },
        {
          title: '开始日期',
          dataIndex: 'StartDate'

        },
        {
          title: '结束日期',
          dataIndex: 'EndDate'

        }

     
      ],
      data:[]
    }
  },
  mounted(){

    this.GetPageList();
    this.InitCompanys();
  },
  methods:{
    InitCompanys(){
      let that=this;
      that.cardtypelist=[];
      that.$axios.post("/api/Card/GetCardTypeList", null).then((res) => {
        if (res.data.message=="success"){
          that.cardtypelist = res.data.data;
        }
      });
    },
    uploadImage(file){
      let _this = this,
          data = file.file,
          fileParams = new FormData();
      fileParams.append('file', data);
      this.$axios.post("/api/SourceDown/Upload", fileParams,{
        headers: { "Content-Type": "multipart/form-data" }}).then((res) => {
        if (res.data.message == 'success') {
          _this.$set( _this.admin,'Picture',res.data.data[0]);
        }else {
          return _this.$message.error(res.data.message);
        }
      });
    },
    showModel(){
      this.visible=true;this.modeltitle='发卡';this.admin={}
    },
    handleSubmit(){
      this.GetPageList();
    },

    OnChange(date,dateString){
           if (dateString.length>0)

            this.queryParam.startdate=dateString[0]?this.$moment(dateString[0]).format('YYYY-MM-DD'):'';
           this.queryParam.enddate=dateString[1]?this.$moment(dateString[1]).format('YYYY-MM-DD'):'';
            },
    handleTableChange(pagination){
      this.pagination.current = pagination.current;
      this.pagination.pageSize = pagination.pageSize;
      this.queryParam.page = pagination.current;
      this.queryParam.size = pagination.pageSize;
      this.GetPageList();
    },
    GetPageList(){
      let that=this;
      that.data=[];
      that.pagination.total=0;


      this.$axios.post("/api/Card/GetCardHistoryList", that.queryParam).then((res) => {
        if (res.data.message=="success"){
          that.pagination.total = res.data.data.Total;
          that.data = res.data.data.Data;

        }

      });

    },

    deleteclomun(id){
      let that=this;

      that.$axios.post("/api/Card/DeleteCardType", {Id:id}).then((res) => {

        if (res.data.message=='success'){
          that.data=that.data.filter((item)=>{return item.Id!=id});
          that.$message.success('删除成功!');

        }else{
          that.$message.error('删除失败!');
        }
      });


    },
    editclomun(id){
      let that=this;


      that.admin={};
      that.modeltitle='修改';
      that.visible=true;
      that.$axios.post("/api/SourceDown/GetCardType", {Id:id}).then((res) => {
        if (res.data.message=='success'){
          that.admin=res.data.data;
        }

      });

    },
    uppassOk(){
      let that=this;
      let url;
      if (that.modeltitle=='发卡'){
        url="/api/Card/AddCard";
      }
      that.$axios.post(url, that.admin).then((res) => {
        console.log(res)
        if (res.data.message=='success'){
          this.visible=false;
          that.$message.success('操作成功!');
          that.GetPageList();

        }else{
          that.$message.error('操作失败!');
        }
      });
    }
  }
}
</script>

<style scoped>

</style>